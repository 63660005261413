<template>
  <section>
    <!-- LANDING -->
    <div class="home pt-5">
      <div class="container">
        <h1 class="content-header">Welcome to this section on personal resilience</h1>
        <!-- CONTENT -->
        <div class="content-wrapper">
          <div class="row">
            <div class="col-sm order-sm-1">
              <button class="btn" @click="openPopup = true">
                <img
                  src="@/assets/img/_content/personal-landing-lightbox.png"
                  class="img-fluid"
                  alt="Bio"
                >
              </button>
            </div>
            <div class="col-sm">
              <div class="row align-items-center">
                <!-- <div class="col-sm">
                  <img
                    src="@/assets/img/_content/personal-learn-1.jpg"
                    class="img-thumb img-fluid"
                    alt=""
                  >
                </div> -->
                <div class="col-sm">
                  <h2 class="pt-4">Meet Diana</h2>
                  <p>Dr. Diana Brecher is a clinical psychologist who started working at Toronto Metropolitan University in 1991. She spent the first 25 years working in the Counselling Centre. In 2016 she was appointed the Scholar-in-Residence, Positive Psychology to the ThriveRU program. Her role is to infuse the classroom, co-curricular programming, and services with principles and practices of flourishing.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROUTING NAV -->
      <div
        class="routing-nav"
        id="route02"
      >
        <h2>How would you like to support your students today?</h2>
        <div>
          <router-link to="personal/learn" class="routing-nav__btn routing-nav__btn--large">
            <strong>Learn</strong> about personal resilience
          </router-link>
          <router-link to="personal/discuss" class="routing-nav__btn routing-nav__btn--large">
            <strong>Discuss</strong> resilience with students
          </router-link>
          <router-link to="personal/share" class="routing-nav__btn routing-nav__btn--large">
            <strong>Share</strong> resources with students
          </router-link>
          <router-link to="personal/design" class="routing-nav__btn routing-nav__btn--large">
            <strong>Design</strong> curriculum that promotes resilience
          </router-link>
          <router-link to="personal/review" class="routing-nav__btn routing-nav__btn--large">
            <strong>Review</strong> research by resilience experts
          </router-link>
        </div>
      </div>

      <!-- MODAL -->
      <modal-popup v-if="openPopup" @close="openPopup = false">
        <div class="video-player">
          <!-- video element -->
          <!-- <vue-plyr>
            <div class="plyr__video-embed">
              <iframe
                src="https://www.youtube.com/embed/C9zObuzQG4g?cc_load_policy=1"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              ></iframe>
            </div>
          </vue-plyr> -->
          <div class="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/m8nFksD-6po?rel=0&cc_load_policy=1&modestbranding=1"
              title="YouTube video"
              allowfullscreen
            ></iframe>
          </div>
          <div class="accordion">
            <accordion
              headerText="Transcript"
              accordionID="transcript01"
            >
              <p>Hello. My name is Diana Brecher, and I'm here to tell you a bit about what you can expect from this section of the Toolkit. So personal resilience is something that can simply be defined as our ability to bounce back after we've faced a challenge or adversity. And sometimes this is based entirely on our inner resources.</p>
              <p>And sometimes we really need the external community to support us in this bouncing back. And sometimes it's a combination of the two. This is really about learning how to bounce back after things have gone wrong. And if you're not bouncing back, perhaps you're moving forward. And this is of course all in the context of post-secondary education.</p>
              <p>So in this section of the toolkit, I've created a series of videos. I'll start off with describing the first one, the longest one, which is a one hour video on the five factor model of resilience that I developed some time ago. And it's essentially the highlights of a training program that I've created on how to help people acquire the skills necessary to be more personally resilient.</p>
              <p>And this is really focused on the internal resilience primarily. I also created a series of much shorter videos, 15 to 20 minutes long on various topics, directed to faculty for the most part. The topics include growth mindset, flow, which is about optimal performance. It's on character strengths, habit formation and the importance of sleep as a thriving and learning strategy. </p>
              <p>And in these five short videos I focused on the topic itself, which could be just for the use of the instructor or shared with students. And then I provide a debrief at the end, from which I talk about various issues related to it and provide some resources, if you want to delve deeper. Beyond the videos, I also Incorporated a workbook that I've written called Cultivate Your Happiness, which is based primarily on exercises, cultivated, curated rather, from positive psychology and the mindfulness tradition primarily. I've chosen 13 themes which repeat for fall and winter term, each exercise for each week or each term is based on a challenge that students are likely to face in that week or term.</p>
              <p>And this was really based on my observations after 25 years working in the counselling center at Ryerson University. </p>
              <p>Students can download this as an independent study; it's a workbook, that's a downloadable PDF, or I've also created a series of slides that explains each of these exercises that could be taught in the context of your curriculum in class if you choose to do that. </p>
              <p>In addition I have a program called Writing for Wellness, which is co-created with my colleague, Dr. Natalya Androsova. It's directed primarily to faculty and staff, and it's a series of writing prompts. And so there's both a workbook and because we've gone digital there are slides with these various writing prompts that are linked in theme back to the same thirteen things identified in the Cultivate your Happiness workbook.</p>
              <p>There are also flashcards that I co-created with my colleague Juannittah Kamera, who is our health promotion nurse. And we focused on PERMA V, which is an acronym for positivity, engagement, relationships, meaning, achievement and vitality, and those are what we call the big tent of positive psychology.</p>
              <p>There are all the different areas in which one can flourish. And then finally, I co-created the thriving and action online resource with Dr. Deena Kara Shaffer which includes a faculty and staff guide and how to use this whole web resource in your class if you wish. And this is based on the Thriving in Action program Deena and I created together which is not included in this kit, but the online version is. </p>
              <p>So why does personal resilience matter so much to me? I've always cared about resilience. Although I've never called it that when I was a child, my wish as a child was always that everyone would be healthy and happy.</p>
              <p>And I had no idea how that might happen, but it's something I wished for on a regular basis, figuring if I wished for that and everything else would fall into place. Well, when I eventually became a clinical psychologist that became in essence, my job, which was to work with our students in the counselling center to help them to become healthy and happy.</p>
              <p>But eventually after 25 years, of seeing clients being a director, training graduate students, I felt I wanted to move upstream and I became so I went back to school and I did a seven-month certificate in positive psychology through the Flourishing Center, and then created the program called Thrive RU, standing for Ryerson University, in which many of these resources were originally developed. And it became a way for me to reach students before they needed counselling. Perhaps when things were going okay, but, you know, it wouldn't hurt if they learned some extra skills along the way.</p>
              <p>And so that's what this resilience training program became. The workbook was part of that to reach people who wouldn't necessarily come to the training program and so forth. So the five-factor model resilience is about mindfulness, gratitude, optimism, self-compassion and perseverance. And they're like pieces of a puzzle.</p>
              <p>Even if only one of these resonates for you, you could pursue it and it can be the beginning of really helping yourself bounce back after things have gone wrong. So the way the toolkit is set up is you could be fairly involved in delivering the material yourself, or you can be directing students to some of these resources and then creating space in the classroom to have a discussion about some of these topics, whatever works best for you and your situation.</p>
              <p>So I want to thank you for your time and your interest in personal resilience. All the best. Thanks, bye.</p>
            </accordion>
          </div>
          <p><a :href="`${publicPath}docs/personal/Meet-Diana.pdf`" target="_blank">Open transcript in a new tab</a></p>
        </div>
      </modal-popup>

      <!-- MAIN NAV -->
      <nav class="navbar main-nav">
        <div class="container">
          <router-link class="main-nav__item" to="/students-resilience/community">COMMUNITY</router-link>
          <router-link class="main-nav__item" to="/students-resilience/personal">PERSONAL</router-link>
          <router-link class="main-nav__item" to="/students-resilience/academic">ACADEMIC</router-link>
          <router-link class="main-nav__item" to="/students-resilience/career">CAREER</router-link>
        </div>
      </nav>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import ModalPopup from '@/components/ModalPopup.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation
//   // Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  components: {
    // SidebarMenu,
    Accordion,
    ModalPopup
    // Hooper,
    // Slide,
    // HooperNavigation
    // HooperPagination
  },
  data () {
    return {
      hooperSettings: {
        itemsToShow: 4,
        trimWhiteSpace: true,
        centerMode: false
      },

      openPopup: false,
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
